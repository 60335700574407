// $primary: #fc6ac2;
// $secondary: white;
// $accent: #fa32aa;
// $background-color: #fceef6;
// $background-accent: #ffdff1;
// $button-bg-color: #f86cc0;
// $button-bg-hover-color: #fc4ab5;
// $text-color: rgba(87, 87, 87, 0.9);
$primary: #ffffff;
$secondary: #fbdeb2;
$third: #fcdc00;
$accent: #262855;
$background-color: #ed552f;
$background-accent: #ed552f;
$secondary-bg-color: #ffde01;
$button-bg-color: #262855;
$button-bg-hover-color: #26285575;
$text-color: rgba(87, 87, 87, 0.9);
$error: #84d9d3;
