@import './assets/sass/styles.scss';

.App {
  button.button,
  a.button {
    background-color: #262855;
    border: 5px solid #fcdc00;
    font-weight: 600;
    padding: 28px 53px;
    color: #ffffff;
    border-radius: 35px;
    font-size: 1.3rem;
    cursor: pointer;
    font-family: 'FredokaOne', 'Ubuntu', Arial -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
    transition: all 0.2s linear;
    &:hover {
      // -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
      // -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
      // box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
      transform: translate(0, 4px);
    }
  }
  a.link {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    &.padded {
      padding-right: 3px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
