// Here goes the font-face declaration (Font files go to 'fonts' directory)

// EXAMPLE:

// @font-face {
//   font-family: 'BuckwheatTC';
//   src: url('../fonts/BuckwheatTC-Regular.otf') format('opentype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'FredokaOne';
  src: url('../fonts/FredokaOne-Regular.woff2') format('woff2'),
    url('../fonts/FredokaOne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
