@import '../../assets/sass/styles.scss';

.Register {
  margin: 5rem;
  padding-top: 60px;
  .title-wrapper {
    text-align: center;
    .title-image {
      max-height: 8vh;
    }
  }
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    &.thanks {
      h3 {
        font-size: 4rem;
        font-weight: 500;
        color: $third;
        font-family: 'FredokaOne', 'Ubuntu', Arial -apple-system,
          BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
          'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;

        text-shadow: -6px -6px 0 $accent, -6px -3px 0 $accent,
          -6px 0px 0 $accent, -6px 3px 0 $accent, -6px 6px 0 $accent,
          -3px -6px 0 $accent, -3px -3px 0 $accent, -3px 0px 0 $accent,
          -3px 3px 0 $accent, -3px 6px 0 $accent, 0px -6px 0 $accent,
          0px -3px 0 $accent, 0px 0px 0 $accent, 0px 3px 0 $accent,
          0px 6px 0 $accent, 3px -6px 0 $accent, 3px -3px 0 $accent,
          3px 0px 0 $accent, 3px 3px 0 $accent, 3px 6px 0 $accent,
          6px -6px 0 $accent, 6px -3px 0 $accent, 6px 0px 0 $accent,
          6px 3px 0 $accent, 6px 6px 0 $accent;
      }
      span {
        color: $primary;
        font-size: 1.2rem;
      }
      .close-button {
        background: none;
        border: none;
        position: absolute;
        top: -10px;
        right: 10px;
        color: $primary;
        font-size: 3rem;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          transform: scale(1.2);
          transition-duration: 0.5s;
        }
      }
    }
    a {
      color: $primary;
    }
    h2 {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 5px;
      color: $third;
      font-family: 'FredokaOne', 'Ubuntu', Arial -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;

      text-shadow: -6px -6px 0 $accent, -6px -3px 0 $accent, -6px 0px 0 $accent,
        -6px 3px 0 $accent, -6px 6px 0 $accent, -3px -6px 0 $accent,
        -3px -3px 0 $accent, -3px 0px 0 $accent, -3px 3px 0 $accent,
        -3px 6px 0 $accent, 0px -6px 0 $accent, 0px -3px 0 $accent,
        0px 0px 0 $accent, 0px 3px 0 $accent, 0px 6px 0 $accent,
        3px -6px 0 $accent, 3px -3px 0 $accent, 3px 0px 0 $accent,
        3px 3px 0 $accent, 3px 6px 0 $accent, 6px -6px 0 $accent,
        6px -3px 0 $accent, 6px 0px 0 $accent, 6px 3px 0 $accent,
        6px 6px 0 $accent;
      // -webkit-text-stroke: 6px $accent; /* width and color */
    }
    .register-notice {
      color: $primary;
      // text-shadow: 0px 6px 0px rgba(0, 0, 0, 0.31);
      font-size: 1.2rem;
      margin-bottom: 25px;
    }
    .campaign-over-wrapper {
      text-align: center;
      color: $primary;
      font-size: 1.2rem;
      span {
        display: block;
      }
    }
    #register-form {
      .help.is-danger {
        color: $error;
        font-size: 0.85rem;
      }
      .field {
        min-width: 700px;
        &.checkbox-field {
          text-align: center;
          margin-top: 40px;
        }
        input {
          color: $accent;
          // text-shadow: 0px 6px 0px rgba(0, 0, 0, 0.31);
          border: none;
          padding: 25px 15px;
          // background-color: transparent;
          // -webkit-box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.3);
          // -moz-box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.3);
          // box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.3);
          font-family: 'Regular', 'Ubuntu', Arial -apple-system,
            BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;

          border-radius: 25px;
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $accent;
            opacity: 1; /* Firefox */
            font-family: 'Regular', 'Ubuntu', Arial -apple-system,
              BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
              'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $accent;
            font-family: 'Regular', 'Ubuntu', Arial -apple-system,
              BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
              'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $accent;
            font-family: 'Regular', 'Ubuntu', Arial -apple-system,
              BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
              'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
          }
        }
        .checkbox {
          color: $primary;
          // text-shadow: 0px 6px 0px rgba(0, 0, 0, 0.31);
          font-size: 1.2rem;
          input {
            margin-right: 15px;
            text-shadow: none;
            box-shadow: none;
            padding: initial;
          }
        }
      }
      .submit-btn-wrapper {
        text-align: center;

        .button {
          margin: 20px 0;
          // // font-size: 1.6rem;
          // height: 2em;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Register {
    margin: 0;
    padding-top: 0;
    .container {
      h2 {
        font-size: 2.2rem;
        letter-spacing: 2px;
        text-shadow: -4px -4px 0 $accent, -4px -2px 0 $accent,
          -4px 0px 0 $accent, -4px 2px 0 $accent, -4px 4px 0 $accent,
          -2px -4px 0 $accent, -2px -2px 0 $accent, -2px 0px 0 $accent,
          -2px 2px 0 $accent, -2px 4px 0 $accent, 0px -4px 0 $accent,
          0px -2px 0 $accent, 0px 0px 0 $accent, 0px 2px 0 $accent,
          0px 4px 0 $accent, 2px -4px 0 $accent, 2px -2px 0 $accent,
          2px 0px 0 $accent, 2px 2px 0 $accent, 2px 4px 0 $accent,
          4px -4px 0 $accent, 4px -2px 0 $accent, 4px 0px 0 $accent,
          4px 2px 0 $accent, 4px 4px 0 $accent;
      }
      .register-notice {
        font-size: 0.9rem;
      }
      #register-form {
        .field {
          min-width: 90vw;
        }
        // .button {
        //   font-size: 0.9rem;
        //   padding: 15px 30px 15px 30px;
        // }
      }
    }
  }
}
