@import '../../assets/sass/styles.scss';
.Main {
  // background-image: url('../../assets/images/background_2.jpg'),
  //   linear-gradient(
  //     90deg,
  //     rgba(1, 25, 94, 1) 0%,
  //     rgba(2, 32, 104, 1) 40%,
  //     rgba(1, 21, 86, 1) 100%
  //   );
  background-image: url('../../assets/images/background.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  background-color: $background-color;
  .hidden-desktop {
    display: none;
  }
  .hidden-mobile {
    display: initial;
  }
  .wheat {
    max-height: 70px;
    position: absolute;
    top: 45px;
    right: 85px;
  }

  .banner {
    text-align: center;
    padding: 0 0;
    min-height: 110vh;
    background-image: url('../../assets/images/banner.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    .banner-margins {
      margin: 0 5vw;
      .banner-header-mobile {
        display: none;
        h1 {
          font-family: 'FredokaOne', 'Ubuntu', Arial -apple-system,
            BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
          color: $secondary;
          font-weight: 800;
          font-size: 4.5rem;
          line-height: 1;
        }
        h2 {
          line-height: 1;
          padding-bottom: 20px;
          color: $secondary;
          font-weight: 800;
          font-size: 4.5rem;
          font-family: 'FredokaOne', 'Ubuntu', Arial -apple-system,
            BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
        }
      }
      .banner-header {
        max-width: 40vw;
        text-align: left;
        position: absolute;
        right: 0;
        bottom: -10%;
        p {
          color: $primary;
          font-size: 1.2rem;
          padding-right: 60px;
          font-family: 'FredokaOne', 'Ubuntu', Arial -apple-system,
            BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
          .block-span {
            display: block;
            &.secondary {
              color: $secondary;
            }
          }
          .bold {
            font-weight: 600;
          }
        }
        .button {
          font-size: 1.2rem;
          z-index: 999;
        }
        .campaign-period {
          font-family: 'FredokaOne', 'Ubuntu', Arial -apple-system,
            BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
          color: $primary;
          padding: 30px 0 40px 0;
          font-size: 0.9rem;
        }
      }
      .banner-prize {
        position: absolute;
        right: 0;
        left: -46vw;
        margin: auto;
        top: 62vh;
        max-width: 37vw;
        max-height: 80vh;
      }
    }
  }
  .register-form {
    background-color: $background-color;
    margin-top: 0.75rem;
  }
  .rules-section {
    background-color: $background-accent;
  }
  .winners-section {
    background-color: $background-color;
  }
  .footer {
    background-color: $background-accent;
    .container {
      text-align: end;
    }
  }
}

/* PC BIG SCREEN */
@media (min-width: 1441px) {
  .Main {
    .banner {
      .banner-margins {
        margin: 0 5vw;
        .banner-header {
          .fazer-logo {
            max-height: 70px;
            margin-bottom: 38px;
          }
          h1 {
            font-size: 5rem;
          }
          h2 {
            padding-bottom: 50px;
            font-size: 5rem;
          }
          p {
            font-size: 1.6rem;

            .block-span {
              display: block;
            }
            .bold {
              font-weight: 600;
            }
          }
          .campaign-period {
            padding: 30px 0 50px 0;
            font-size: 1.5rem;
          }
        }
        .banner-prize {
          max-width: 40vw;
          top: 56vh;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Main {
    background-color: $background-accent;
    .hidden-desktop {
      display: initial;
    }
    .hidden-mobile {
      display: none;
    }
    .wheat {
      max-height: 40px;
      top: 15px;
      right: 15px;
    }
    .banner {
      min-height: 110vh;
      padding-bottom: 10vh;
      background-image: url('../../assets/images/banner_mobile.png');
      background-size: contain;
      background-repeat: no-repeat;
      // background-position: -2vw -10%;
      .columns {
        display: flex;
        flex-direction: row;
        padding: 75vh 0 0 0;
        .column {
          &.banner-header {
            padding: initial;
            max-width: 100vw;
            position: initial;
            text-align: center;
            h1 {
              // font-size: 1.5rem;
              // line-height: 1;
              display: none;
            }
            h2 {
              // font-size: 2rem;
              // line-height: 1;
              // padding-bottom: 5px;
              display: none;
            }
            p {
              font-size: 1rem;
              padding-right: 20vw;
              text-align: left;
              padding-left: 20px;
            }
            .campaign-period {
              font-size: 0.8rem;
              padding: 15px 20vw 20px 20px;
              text-align: left;
            }
            .fazer-logo {
              position: absolute;
              top: 35px;
              left: 20px;
              max-height: 27px;
            }
          }
          &.is-half-mobile {
            padding: 0;
          }
          .banner-prize {
            position: absolute;
            max-width: 68%;
            height: auto;
            bottom: 10px;
            top: 12vh;
            left: -80vw;
          }
          .button {
            // display: none;
          }
        }
      }
      .banner-prize {
        max-width: 100vw;
      }
    }
    .prizes {
      padding: 2rem;
    }
  }
}
